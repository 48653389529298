
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
        },
    })
  
    // *****************************************************************************************************************
    export default class DevRest extends Vue {
        // Data function
        public data() {
            return {
                systemsIconPathAndName:
                    require("@/assets/images/icon_systems.png"),
            };
        }
    }
